import React, { SyntheticEvent, useEffect } from "react";
import { TextField, Autocomplete, CircularProgress, Box } from "@mui/material";
import { Student } from "../../types/types";
import { useRecoilState } from "recoil";
import { postAtom, studentsAtom, switchAtom } from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import { verifySubmissionAtom } from "../../recoil/atoms";

export default function SelectStudents() {
  const [post, setPost] = useRecoilState(postAtom);
  const verifySubmission = useRecoilValue(verifySubmissionAtom);
  const students = useRecoilValue(studentsAtom);
  const languageSpanish=useRecoilValue(switchAtom);

  const onChangeHandler = (
    event: SyntheticEvent<Element, Event>,
    value: Student | null
  ) => {
    if (value) {
      setPost({
        ...post,
        SID: value.SID,
        studentFirstName: value.childFirstName,
        studentLastName: value.childLastName,
        homeroom: value.homeroom,
        studentId: value.id,
      });
    }
  };

  return (
    <div>
      {!students && (
        <Box sx={{ display: "block", textAlign: "center" }}>
          <CircularProgress size={50} />
        </Box>
      )}
      {/* assigning key equal to verify submission is a hack to reset the Autocomplete component */}
      {students && (
        <Autocomplete
          disablePortal
          id="select-student-field"
          key={verifySubmission + ""}
          options={students}
          getOptionLabel={(option) =>
            option.childFirstName + " " + option.childLastName
          }
          onChange={onChangeHandler}
          renderInput={(params) => (
            <TextField required {...params} label={!languageSpanish?"Select Student":"Seleccionar estudiante"} />
          )}
        />
      )}
    </div>
  );
}
