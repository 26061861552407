import { YEAR } from "../types/types";

export enum PERMISSION {
  EDIT_STAFF = "Edit Staff",
  EDIT_SETTINGS = "Edit Settings",
  SEE_ALL_ROASTER = "See All Roasters",
}

export const PERMISSIONS: Array<PERMISSION> = [
  PERMISSION.EDIT_STAFF,
  PERMISSION.EDIT_SETTINGS,
  PERMISSION.SEE_ALL_ROASTER,
];

export enum SettingsSections {
  PERIODS = "Narrative Periods",
  SECTIONS = "Narrative Sections",
  OBJECTIVES = "Narrative Objectives",
  OVERRIDE = "Roster Override",
  MANAGE = "Manage School Hours",
}

export enum COLLECTION {
  tardyLogs = "tardyLogs",
}

export const yearsObj = {
  [YEAR.y2021]: { start: 2020, end: 2021 },
  [YEAR.y2122]: { start: 2021, end: 2022 },
  [YEAR.y2223]: { start: 2022, end: 2023 },
  [YEAR.y2324]: { start: 2023, end: 2024 },
};

export const COLLECTIONS = Object.values(COLLECTION);
