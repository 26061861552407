import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useWindowDimensions from "../../libraries/useWindowDimensions";
import { TardyLogRecord } from "../../types/types";
import { useRecoilValue } from "recoil";
import { homeroomsAtom, postAtom, studentsAtom } from "../../recoil/atoms";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid-pro";

type Props = {
  rows: TardyLogRecord[];
};

export default function RawDataTable({ rows }: Props) {
  const homerooms = useRecoilValue(homeroomsAtom);
  const students = useRecoilValue(studentsAtom);
  const formType = useRecoilValue(postAtom);

  const dateFormatter = (params: GridValueFormatterParams) => {
    const dateObj = new Date(params.value * 1000);
    return `${dateObj.toLocaleDateString()} - ${dateObj.toLocaleTimeString()} `;
  };

  const hrFormatter = (params: GridValueFormatterParams) => {
    if (!homerooms) return;
    const selectedHomeroom = homerooms.find(
      (homeroom) => homeroom.id === params.value
    );
    return selectedHomeroom?.name ?? "";
  };

  const formTypeFormatter = (params: GridValueFormatterParams) => {
    return params.value;
  };
  //   const phoneFormatter = (params) => {
  //     if (!students) return;
  //     const currentStudent = students.find((s) => s.SID === params.value);
  //     return currentStudent ? currentStudent.primaryPhone : "";
  //   };
  //phoneFormatter has not been called somewhere.

  const rawColumns: GridColDef[] = [
    {
      field: "SID",
      headerName: "ID",
      minWidth: 100,
      flex: 0.6,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
    },
    {
      field: "studentFirstName",
      headerName: "First Name",
      minWidth: 100,
      flex: 1,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
    },
    {
      field: "studentLastName",
      headerName: "Last Name",
      minWidth: 100,
      flex: 1,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
    },
    {
      field: "seconds",
      headerName: "Time",
      minWidth: 100,
      flex: 1.5,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      valueFormatter: dateFormatter,
    },
    {
      field: "homeroom",
      headerName: "Homeroom",
      minWidth: 100,
      flex: 1.5,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
      valueFormatter: hrFormatter,
    },

    {
      field: "reason",
      headerName: "Reason",
      minWidth: 100,
      flex: 1,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
    },
    {
      field: "logType",
      headerName: "Type",
      minWidth: 100,
      flex: 1,
      headerClassName: "tableHeader",
      cellClassName: "hoverPointer",
    },
  ];
  const window = useWindowDimensions();
  return (
    <>
      {rows && students && homerooms && (
        <div style={{ height: window.height - 300, width: "100%" }}>
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={rows}
                columns={rawColumns}
                rowHeight={32}
                // s
                components={{
                  Toolbar: GridToolbar,
                }}
                getRowClassName={(params) => {
                  const suffix = params.row.rowIndex % 2 === 0 ? "Odd" : "Even";
                  return `row${suffix}`;
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
