import React from "react";
import { Container } from "@mui/material";
import NavBar from "../components/Navigation/Navbar";
import PerDayContainer from "../components/TardyReports/PerDayContainer";

export default function PerDayReport() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="md" sx={{ minHeight: "100vh", mt: "64px" }}>
        <PerDayContainer />
      </Container>
    </>
  );
}
