import React from "react";
import { Button, DialogTitle, Dialog } from "@mui/material";
import { useRecoilState } from "recoil";
import { postAtom } from "../../recoil/atoms";
import { blankTardyForm } from "../../libraries/blankForms";
import {  checkedAtom,verifySubmissionAtom } from "../../recoil/atoms";

export default function SubmissionDialog() {
  const [post, setPost] = useRecoilState(postAtom);
  const [verifySubmission, setVerifySubmission] = useRecoilState(verifySubmissionAtom)
  const handleClose = () => {
    setPost(blankTardyForm);
    setVerifySubmission(false);
  };

  return (
    <Dialog onClose={handleClose} open={verifySubmission}>
      <DialogTitle>
        Your Submission For {post.studentFirstName + " " + post.studentLastName} Has Been Recorded
      </DialogTitle>

      <Button sx={{ margin: 2 }} variant="contained" onClick={() => handleClose()}>
        Submit Another Response
      </Button>
    </Dialog>
  );
}
