import React, { FormEvent } from "react";
import { Grid, Button } from "@mui/material";
import SelectStudents from "./SelectStudents";
import FormField from "./FormField";
import Confirmation from "./Confirmation";
import { useRecoilValue } from "recoil";
import { checkedAtom, switchAtom} from "../../recoil/atoms";

type Props = {
  submitForm: (e: FormEvent) => void;
};

export default function SignInForm({ submitForm }: Props) {
  const checked = useRecoilValue(checkedAtom);
  const languageSpanish=useRecoilValue(switchAtom);

  return (
    <form onSubmit={submitForm}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <FormField
            id="caregiverFirstName"
           
            label={!languageSpanish? "Caregiver's First Name" :"Nombre del cuidador"}
            multiline={false}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FormField
            id="caregiverLastName"
            label={!languageSpanish?"Caregiver's Last Name":"Apellido del cuidador"}
            multiline={false}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectStudents />
        </Grid>
        <Grid item sm={12} xs={12}>
          <FormField
            id="reason"
            label={!languageSpanish?"Reason For Being Late":" Razón para llegar tarde"}
            multiline={true}
          />
        </Grid>
        <Grid item sm={12}>
          <Confirmation />
        </Grid>
        <Grid item xs={12}>
          {
            languageSpanish &&(
              <Button
            variant="contained"
            fullWidth
            type="submit"
            disabled={!checked}
          >
          
Entregar
          </Button>
            )
          }
          {
            !languageSpanish &&(
              <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={!checked}
            >
              Submit
            </Button>
            )

          }
         
        </Grid>
      </Grid>
    </form>
  );
}
