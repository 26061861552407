import React, { ChangeEvent } from "react";
import { Typography, TextField } from "@mui/material";
import { useRecoilState } from "recoil";
import { postAtom } from "../../recoil/atoms";

type FormFieldInputType = {
  label: string;
  id: string;
  multiline: boolean;
};

export default function FormField({
  label,
  id,
  multiline,
}: FormFieldInputType) {
  const [post, setPost] = useRecoilState(postAtom);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPost((prevValue) => ({ ...prevValue, [id]: e.target.value }));
    // console.log("after handle change",post)
  };

  return (
    <>
      <Typography variant="body1">{label}</Typography>
      <TextField
        fullWidth
        id={id}
        required
        name={id}
        multiline={multiline}
        value={post[id]}
        onChange={handleChange}
      />
    </>
  );
}
