import React from "react";
import { Route, Routes } from "react-router-dom";

import CustomRoute from "./CustomRoute";
import { PERMISSION } from "./libraries/objects";
import LoginPage from "./pages/LoginPage";
import SignInForm from "./pages/SignInForm";
import PerDayReport from "./pages/PerDayReport";
import AddActiveToCollection from "./dbchanges/AddActiveToCollection";
import PerWeekReport from "./pages/PerWeekReport";

import EarlyPickUpPage from "./pages/EarlyPickUp";
import PerStudentReport from "./pages/PerStudentReport";
import SettingsPage from "./pages/SettingsPage";

import { RawDataPage } from "./pages/RawDataPage";

export const routes = (
  <Routes>
    <Route path="/" element={<SignInForm />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/earlypickup" element={<EarlyPickUpPage />} />

    {/* <Route
      path="/staff"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <StaffPage />
        </CustomRoute>
      }
    /> */}
    <Route
      path="/settings"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <SettingsPage />
        </CustomRoute>
      }
    />

    <Route
      path="/add-active"
      element={
        <CustomRoute permission={PERMISSION.EDIT_STAFF}>
          <AddActiveToCollection />
        </CustomRoute>
      }
    />
    <Route
      path="/perStudentReport"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <PerStudentReport />
        </CustomRoute>
      }
    />
    <Route
      path="/perDayReport"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <PerDayReport />
        </CustomRoute>
      }
    />
    <Route
      path="/perWeekReport"
      element={
        <CustomRoute permission={PERMISSION.EDIT_SETTINGS}>
          <PerWeekReport />
        </CustomRoute>
      }
    />
   <Route
      path="/settings"
      element={
        <CustomRoute permission={PERMISSION.EDIT_STAFF}>
          <SettingsPage />
        </CustomRoute>
      }
    />
     <Route
      path="/raw-data-table"
      element={
        <CustomRoute permission={PERMISSION.EDIT_STAFF}>
          <RawDataPage/>
        </CustomRoute>
      }
    />
  </Routes>
);
