import { Box, Typography } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect, useState } from "react";
import { DataSnapshot, get, getDatabase, onValue, ref } from "firebase/database";
import { TardyLogRecord } from "../../types/types";
import HistoryLog from "../TardyReports/HistoryLog";
import HistoryDialogContent from "../TardyReports/HistoryDialogContent";

type Props = {
  tardyLogs: TardyLogRecord[];
  earlyPickUpLogs: TardyLogRecord[];
};

function PrintTable({ tardyLogs, earlyPickUpLogs }: Props) {
  return (
    <>
      <Header />
      <Footer />

      <table id="print-table" style={{ width: "100%", backgroundColor: "#fff" }}>
        <thead>
          <tr>
            <td>
              <div className="page-header-space"></div>
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <Typography variant="h2" sx={{ textAlign: "center" }}>
                Tardy and Early Pick Up Logs
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <HistoryDialogContent
                selectedStudentTardyLogs={tardyLogs}
                selectedStudentEarlyPickUps={earlyPickUpLogs}
              />
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <div className="page-footer-space"></div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}

export default PrintTable;
