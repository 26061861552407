function Footer() {
  const today = new Date().toDateString();

  document.title = `The Gathering Place Tardy and Early Pick Up Logs printed on ${today}.`;

  return (
    <footer className="page-footer">
      {`The Gathering Place Tardy and Early Pick Up Logs printed on ${today}.`}
    </footer>
  );
}

export default Footer;
