import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import PerWeekTable from "./PerWeekTable";
import { useRecoilValue } from "recoil";
import { logsLoadingAtom } from "../../recoil/atoms";

export default function PerWeekContainer() {
  const loading = useRecoilValue(logsLoadingAtom);

  return (
    <>
      <Typography sx={{ pt: 3, pb: 2 }} variant="h3">
        Tardies Per Week
      </Typography>

      {loading && (
        <Box sx={{ display: "block", textAlign: "center" }}>
          <CircularProgress size={50} />
        </Box>
      )}
      {!loading && <PerWeekTable />}
    </>
  );
}
