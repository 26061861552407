import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";

type PerDayByDateTableProps = {
  sortedByDate: { [key: string]: { count: number; logs: Log[] } };
  dates: string[];
};

type Log = {
  childFirstName: string;
  childLastName: string;
  timeForPrint: string;
  type: string;
};

export default function PerDayByDateTable({
  sortedByDate,
  dates,
}: PerDayByDateTableProps) {
  // console.log("here", sortedByDate);
  return (
    <>
      {dates.map((date) => (
        <Accordion key={date}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              {date} - {sortedByDate[date].count} Records
            </Typography>
            <Typography></Typography>
          </AccordionSummary>
          <AccordionDetails>
            {sortedByDate[date].logs && (
              <>
                {sortedByDate[date].logs.map(
                  (
                    { childFirstName, childLastName, timeForPrint, type },
                    index
                  ) => (
                    <Typography
                      key={childFirstName + childLastName + date + index}
                    >{`${childFirstName} ${childLastName} - ${timeForPrint} - ${type}`}</Typography>
                  )
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
