import React, { useState, useEffect } from "react";
import { CircularProgress, Box, Typography } from "@mui/material";
import { PerStudentTimeFilter } from "./PerStudentTimeFilter";
import {
  homeroomsAtom,
  logsLoadingAtom,
  sortedEarlyPickUpAtom,
  sortedTardyLogsAtom,
  studentsAtom,
} from "../../recoil/atoms";
import { useRecoilValue } from "recoil";
import RawDataTable from "./RawDataTable";
import { LogType, RawDataRow, TardyLogRecord } from "../../types/types";

// Rawdatatable component does not exist now

export default function RawDataContainer() {
  const [rows, setRows] = useState<RawDataRow[]>([]);
  const homerooms = useRecoilValue(homeroomsAtom);
  const students = useRecoilValue(studentsAtom);
  const sortedTardyLogs = useRecoilValue(sortedTardyLogsAtom);
  const sortedEarlyPickUpLogs = useRecoilValue(sortedEarlyPickUpAtom);
  const logsLoading = useRecoilValue(logsLoadingAtom);
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(2664575200000);

  useEffect(() => {
    if (!homerooms || !students || !sortedTardyLogs) {
      return;
    }
    const getPerChildRows = async () => {
      const filteredTardyLogs: RawDataRow[] = [];

      sortedTardyLogs.forEach((log) => {
        const logMilliseconds = log.seconds * 1000;
        if (logMilliseconds > start && logMilliseconds < end) {
          filteredTardyLogs.push({ ...log, logType: "Tardy" });
        }
      });
      const filteredEarlyPickUpLogs: RawDataRow[] = [];
      sortedEarlyPickUpLogs.forEach((log) => {
        const logMilliseconds = log.seconds * 1000;
        if (logMilliseconds > start && logMilliseconds < end) {
          filteredEarlyPickUpLogs.push({ ...log, logType: "Early Pick Up" });
        }
      });
      setRows([...filteredTardyLogs, ...filteredEarlyPickUpLogs]);
    };
    getPerChildRows();
  }, [homerooms, students, sortedTardyLogs, start, end, sortedEarlyPickUpLogs]);

  return (
    <>
      <Typography sx={{ pt: 3, pb: 2 }} variant="h3">
        Raw Data Per Kid
      </Typography>

      <PerStudentTimeFilter setStart={setStart} setEnd={setEnd} />

      {logsLoading && (
        <Box sx={{ display: "block", textAlign: "center" }}>
          <CircularProgress size={50} />
        </Box>
      )}
      {!logsLoading && rows && <RawDataTable rows={rows} />}
    </>
  );
}
