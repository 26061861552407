import React from "react";
import { DataGrid, GridToolbar, GridRowParams } from "@mui/x-data-grid";
import useWindowDimensions from "../../libraries/functions";
import useBuildPerWeekColumns from "../../hooks/useBuildPerWeekColumns";
import useBuildPerWeekRows from "../../hooks/useBuildPerWeekRows";

export default function PerWeekTable() {
  const window = useWindowDimensions();
  const { buildColumns } = useBuildPerWeekColumns();
  const { buildPerWeekRows } = useBuildPerWeekRows();
  const perWeekColumns = buildColumns();
  const perWeekRows = buildPerWeekRows();

  return (
    <>
      <div style={{ height: window.height - 300, width: "100%" }}>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            {perWeekColumns && perWeekRows && (
              <DataGrid
                rows={perWeekRows}
                columns={perWeekColumns}
                rowHeight={32}
                components={{
                  Toolbar: GridToolbar,
                }}
                getRowClassName={(params: GridRowParams) => {
                  const suffix = params.row.rowCount % 2 === 0 ? "Odd" : "Even";
                  return `row${suffix}`;
                }}
                getCellClassName={() => {
                  return "MuiDataGrid-cell--textCenter";
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
