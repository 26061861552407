import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { perChildColumns } from "../../libraries/columns";
import useWindowDimensions from "../../libraries/useWindowDimensions";
import { HandleDetailsClickFunction, Row } from "../../types/types";

type Props = {
  handleDetailsClick: HandleDetailsClickFunction;
  rows: Row[];
};

export const PerStudentTable = ({ handleDetailsClick, rows }: Props) => {
  const window = useWindowDimensions();
  return (
    <>
      <div style={{ height: window.height - 300, width: "100%" }}>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              rows={rows}
              columns={perChildColumns}
              rowHeight={32}
              onRowClick={(data) => {
                return handleDetailsClick(data.row)}}
              components={{
                Toolbar: GridToolbar,
              }}
              getRowClassName={(params) => {
                const suffix = params.row.rowCount % 2 === 0 ? "Odd" : "Even";
                return `row${suffix}`;
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
