import React from "react";
import { Container } from "@mui/material";
import NavBar from "../components/Navigation/Navbar";
import { PerStudentContainer } from "../components/TardyReports/PerStudentContainer";

export default function PerStudentReport() {
  return (
    <>
      <NavBar></NavBar>
      <Container
        maxWidth="lg"
        sx={{ minHeight: "calc(100vh - 64px)", mt: "64px" }}
      >
        <PerStudentContainer />
      </Container>
    </>
  );
}
