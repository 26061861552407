import { Typography, Box } from "@mui/material";
import ManageSchoolHours from "./ManageSchoolHours";

export default function SettingsContainer() {

  return (
    <>
      <Box sx={{ pl: 2, pr: 2 }}>
        <Typography
          sx={{ textAlign: "center", pt: 2, marginBottom: "8px" }}
          variant="h2"
        >
          Settings
        </Typography>
        <ManageSchoolHours />
          
        
      </Box>
    </>
  );
}
