import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { DataGridColumn } from "../types/types";

const createMissingMinutesDisplay =(params:GridValueFormatterParams)=>{

  const days = Math.floor(params.value / (24 * 60));
  const remainingMinutes = params.value % (24 * 60);
  const hours = Math.floor(remainingMinutes / 60);
  const minutes = remainingMinutes % 60;

  let formattedResult = "";

  if (days > 0) {
    formattedResult += `${days} day${days > 1 ? "s" : ""}`;
  }
  if (hours > 0) {
    if (formattedResult !== "") {
      formattedResult += `, `;
    }
    formattedResult += `${hours} hour${hours > 1 ? "s" : ""}`;
  }
  if (minutes > 0) {
    if (formattedResult !== "") {
      formattedResult += `, `;
    }
    formattedResult += `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }

  return formattedResult;
}

export const perChildColumns: DataGridColumn[] = [
  {
    field: "firstName",
    headerName: "First Name",
    minWidth: 100,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 0.4,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    minWidth: 100,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 0.4,
  },
  {
    field: "homeroom",
    headerName: "Homeroom",
    minWidth: 150,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 0.4,
  },
  {
    field: "count",
    headerName: "Tardy",
    minWidth: 90,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 0.4,
  },
  {
    field: "earlyPickUpCount",
    headerName: "Early Pick Up",
    minWidth: 90,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 0.4,
  },
  {
    field: "totalMinutesMissing",
    headerName: "Total Minutes Missing",
    minWidth: 90,
    headerClassName: "tableHeader",
    cellClassName: "hoverPointer",
    flex: 0.4,
    valueFormatter: createMissingMinutesDisplay,
  },
];
