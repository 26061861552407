import React from "react";
import { Grid, Divider, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";

type Props = {
  log: {
    timestamp: Timestamp | null;
    studentLastName: string;
    reason: string;
    studentFirstName: string;
  };
};

const HistoryLog = ({ log }: Props) => {
  let dt = null;
  let day = "No Day Recorded";

  if (log.timestamp) {
    dt = DateTime.fromMillis(log.timestamp.toMillis());
    day = dt.toLocaleString({
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
          {log.studentFirstName} {log.studentLastName}
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          {day}
        </Typography>
        <Typography variant="body2">{log.reason}</Typography>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default HistoryLog;
