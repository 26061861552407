import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { checkedAtom, switchAtom } from "../../recoil/atoms";

const Confirmation = () => {

  const [checked, setChecked] = useRecoilState(checkedAtom);
  const languageSpanish=useRecoilValue(switchAtom);

  const handleChange = () => {
    setChecked((prevValue) => !prevValue);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox required checked={checked} onClick={handleChange} />}
        label={!languageSpanish?"I understand that when my child is late, they miss morning circle, a critical part of the school day where children build community, develop social-emotional skills, and prepare for the day. Being late can lead to my child feeling less connected to school and their peers, which impacts their learning.":"Entiendo que cuando mi hijo llega tarde, se pierde el círculo matutino, una parte fundamental del día escolar donde los niños construyen una comunidad, desarrollan habilidades socioemocionales y se preparan para el día. Llegar tarde puede hacer que mi hijo se sienta menos conectado con la escuela y sus compañeros, lo que afecta su aprendizaje."}
      />
    </FormGroup>
  );
};

export default React.memo(Confirmation);
