import { atom, GetRecoilValue } from "recoil";
import {
  Homeroom,
  Student,
  TardyLogRecord,
  YEAR,
  LogType,
} from "../types/types";
import { selector } from "recoil";
// import { blankForm } from "../libraries/functions";
import { blankTardyForm } from "../libraries/blankForms";
import { SchoolRecord } from "../types/types";

//****** HOMEROOM ATOMS ******//

export const homeroomsAtom = atom<Homeroom[]>({
  key: "homerooms",
  default: [],
});

//****** STUDENT ATOMS ******//

export const studentsAtom = atom<Student[]>({
  key: "students",
  default: [],
});



export const sortedLogsGetter = ({ get }: { get: GetRecoilValue }) => {
  const tardyLogs = get(tardyLogsAtom);

  const tempTardyLogs = [...tardyLogs];
  tempTardyLogs.sort((a, b) => (a.seconds > b.seconds ? 1 : -1));

  return tempTardyLogs;
};

export const sortedEarlyPickUpLogsGetter = ({
  get,
}: {
  get: GetRecoilValue;
}) => {
  const earlyPickUpLogs = get(earlyPickUpLogsAtom);

  const tempEarlyPickUpLogs = [...earlyPickUpLogs];
  tempEarlyPickUpLogs.sort((a, b) => (a.seconds > b.seconds ? 1 : -1));

  return tempEarlyPickUpLogs;
};

export const selectedStudentTardyLogsGetter = ({
  get,
}: {
  get: GetRecoilValue;
}) => {
  const tardyLogs = get(sortedTardyLogsAtom);
  const selectedStudent = get(selectedStudentAtom);
  if (!selectedStudent) return [];

  return tardyLogs.filter((log) => log.studentId === selectedStudent.id);
};

 const selectedStudentEarlyPickUpGetter=({
  get ,
 }:{
  get :GetRecoilValue;

 })=>{
   const earlyPickUps=get(sortedEarlyPickUpAtom);
   const selectedEarlyPickUpStudent=get(selectedStudentAtom);
   if(!selectedEarlyPickUpStudent) return[];
   return earlyPickUps.filter((log)=>log.studentId===selectedEarlyPickUpStudent.id);
 };

 
export const tardyLogsAtom = atom<TardyLogRecord[]>({
  key: "tardyLogs",
  default: [],
});

export const earlyPickUpLogsAtom = atom<TardyLogRecord[]>({
  key: "earlyPickUpLogs",
  default: [],
});

export const sortedTardyLogsAtom = selector({
  key: "sortedTardyLogs",
  get: sortedLogsGetter,
});

export const sortedEarlyPickUpAtom = selector({
  key: "sortedEarlyPickUpLogs",
  get: sortedEarlyPickUpLogsGetter,
});

export const selectedStudentTardyLogsAtom = selector({
  key: "selectedStudentTardyLogs",
  get: selectedStudentTardyLogsGetter,
});

export const selectedStudentEarlyPickUpAtom=selector({
  key: "selectedStudentEarlyPickUp",
  get : selectedStudentEarlyPickUpGetter,

});

export const logsLoadingAtom = atom({
  key: "logsLoading",
  default: false,
});

export const selectedYearAtom = atom<YEAR>({
  key: "selectedYear",
  default: YEAR.y2324,
});

export const selectedStudentAtom = atom<Student | null>({
  key: "selectedStudent",
  default: null,
});

export const postAtom = atom<LogType>({
  key: "post",
  default: blankTardyForm,
});

export const checkedAtom = atom<boolean>({
  key: "checked",
  default: false,
});

export const verifySubmissionAtom = atom<boolean>({
  key: "verified",
  default: false,
});

export const selectedSchoolAtom = atom<SchoolRecord | null>({
  key: "selectedSchool",
  default: null,
});

export const switchAtom = atom<boolean>({
  key: "languageSwitch",
  default: false,
});
