import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { homeroomsAtom } from "../../recoil/atoms";

type PerDayTableProps = {
  dates: string[];
  countByDate: {
    [key: string]: { count: number; logs: { [key: string]: Log[] } };
  };
};

type Log = {
  childFirstName: string;
  childLastName: string;
  homeroomId: string;
  timeForPrint: string;
  type: string;
};

export default function PerDayTable({ dates, countByDate }: PerDayTableProps) {
  const homerooms = useRecoilValue(homeroomsAtom);
  // console.log("here", countByDate);
  return (
    <>
      {dates.map((date) => (
        <Accordion key={date}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              {date} - {countByDate[date].count} Records
            </Typography>
            <Typography></Typography>
          </AccordionSummary>
          <AccordionDetails>
            {homerooms &&
              homerooms.map((homeroom) => (
                <div key={homeroom.id}>
                  {countByDate[date].logs[homeroom.id] && (
                    <>
                      <Typography variant="h5">{homeroom.name}</Typography>
                      {countByDate[date].logs[homeroom.id].map(
                        (
                          { childFirstName, childLastName, timeForPrint, type },
                          index
                        ) => (
                          <Typography
                            key={childFirstName + childLastName + date + index }
                          >{`${childFirstName} ${childLastName} - ${timeForPrint} - ${type}`}</Typography>
                        )
                      )}
                    </>
                  )}
                </div>
              ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
