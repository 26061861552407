import { db } from "../firebase";
import {
  collection,
  query,
  getDocs,
  doc,
  getDoc,
  setDoc,
  where,
} from "firebase/firestore";

export const getStaffMember = async (UID:string) => {
  const staffRef = doc(db, "staff", UID);
  const snap = await getDoc(staffRef);
  return snap;
};

export const createStaffMember = async ({ UID, name, permission }:{UID:string,name:string, permission:string}) => {
  await setDoc(doc(db, "staff", UID), {
    name: name,
    permission: permission,
  });
};

export const getRecentLogsByStudent = async ({ SID, recentTime }:{SID:string, recentTime:number}) => {
  const tardyRef = collection(db, "tardyLogs");
  const queryCheck = query(
    tardyRef,
    where("SID", "==", SID),
    where("seconds", ">", recentTime)
  );
  return await getDocs(queryCheck);
};
