import React from "react";
import Navbar from "../components/Navigation/Navbar";
import { Container } from "@mui/material";
import LoginContainer from "../components/Login/LoginContainer";

const LoginPage = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <LoginContainer />
      </Container>
    </>
  );
};

export default LoginPage;
