import { Box, Button, Grid, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { HandleDetailsClickFunction, Row } from "../../types/types";
import StudentCard from "./StudentCard";

type Props = {
  handleDetailsClick: HandleDetailsClickFunction;
  rows: Row[];
};

const PerStudentCards = ({ handleDetailsClick, rows }: Props) => {
  const [data, setData] = useState(rows);
  const scrollHeight = window.innerHeight - 180;
  const requestSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    const filtered = rows.filter((row) => {
      return (
        row.firstName.toLowerCase().includes(term.toLowerCase()) ||
        row.lastName.toLowerCase().includes(term.toLowerCase())
      );
    });
    setData(filtered);
  };

  const sortFname = () => {
    const sorted = data.slice().sort((current, next) => {
      return current.firstName.localeCompare(next.firstName);
    });
    setData(sorted);
  };
  const sortLname = () => {
    const sorted = data.slice().sort((current, next) => {
      return -1 * current.lastName.localeCompare(next.lastName);
    });
    setData(sorted);
  };

  return (
    <>
      <TextField
        label="search ..."
        type="text"
        placeholder="Search.. "
        onChange={requestSearch}
        variant="outlined"
        fullWidth
        id="fullWidth"
      />
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Button sx={{ marginTop: "10px" }} variant="contained" onClick={sortFname} fullWidth>
            Sort F name
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button sx={{ marginTop: "10px" }} variant="contained" onClick={sortLname} fullWidth>
            {" "}
            Sort L name
          </Button>
        </Grid>
      </Grid>

      <Box
        sx={{
          marginRight: "5px",
          marginTop: "10px",
          height: scrollHeight,
          overflow: "scroll",
        }}
      >
        {rows ? (
          <div>
            {data.map((row, index) => (
              <StudentCard row={row} openHistory={handleDetailsClick} key={index} />
            ))}
          </div>
        ) : (
          <p>no data</p>
        )}
        {/* StudentCard component does not exist now hence the error */}
      </Box>
    </>
  );
};

export default PerStudentCards;
