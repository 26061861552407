import { Timestamp } from "firebase/firestore";

import {
  createStaffMember,
  getRecentLogsByStudent,
  getStaffMember,
} from "../db/queries";
import {
  Homeroom,
  Student,
  User,
  YEAR,
  TardyLogRecord,
  PickUpRecord,
  SchoolRecord,
  TardyLogRecordType,
} from "../types/types";
import { DateTime } from "luxon";
import { groupBy } from "lodash";
import { useState, useEffect } from "react";
import { Row } from "../types/types";

export const sortLogsByStudent = (logs: TardyLogRecord[]) => {
  const tardyLogs: { [key: string]: TardyLogRecord[] } = {};
  logs.forEach((log) => {
    if (tardyLogs[log.SID]) {
      tardyLogs[log.SID].push(log);
    } else {
      tardyLogs[log.SID] = [];
      tardyLogs[log.SID].push(log);
    }
  });
  return tardyLogs;
};

type SetPerStudentRows = {
  logs: TardyLogRecord[];
  earlyPickUpLogs: PickUpRecord[];
  selectedSchool: SchoolRecord | null;
  students: Student[];
  homerooms: Homeroom[];
  start: number | null;
  end: number | null;
};

type Filter = {
  logs: TardyLogRecord[];
  SID: string;
  start: number;
  end: number;
};

type EarlyPickUpFilter = {
  earlyPickUpLogs: PickUpRecord[];
  SID: string;
  start: number;
  end: number;
};

type CalculateMissingTime = {
  earlyPickUpLogs: PickUpRecord[];
  tardyLogs: TardyLogRecord[];
  selectedSchool: SchoolRecord | null;
};

//changed the function
const filterLogsByDateRange = ({ logs, SID, start, end }: Filter) => {
  const filteredLogs = logs.filter((log) => log.SID === SID);

  const logsWithinDateRange = filteredLogs.filter((log) => {
    if (!log.timestamp) return null;
    return log.timestamp.toMillis() > start && log.timestamp.toMillis() < end;
  });

  return logsWithinDateRange.length;
};

const filterEarlyLogsByDateRange = ({
  earlyPickUpLogs,
  SID,
  start,
  end,
}: EarlyPickUpFilter) => {
  const filteredLogs = earlyPickUpLogs.filter((log) => log.SID === SID);

  const logsWithinDateRange = filteredLogs.filter((log) => {
    if (!log.timestamp) return null;
    return log.timestamp.toMillis() > start && log.timestamp.toMillis() < end;
  });
  return logsWithinDateRange.length;
};

const calculateTotalMinutesMissing = ({
  earlyPickUpLogs,
  tardyLogs,
  selectedSchool,
}: CalculateMissingTime) => {
  if (!selectedSchool) return 0;

  const startTime = new Date(selectedSchool.startTime);
  const startTimeHour = startTime.getHours();
  const startTimeMinute = startTime.getMinutes();
  const startTimeMinuteFromMidNight = startTimeHour * 60 + startTimeMinute;

  const endTime = new Date(selectedSchool.endTime);
  const endTimeHour = endTime.getHours();
  const endTimeMinute = endTime.getMinutes();
  const endTimeMinuteFromMidNight = endTimeHour * 60 + endTimeMinute;

  let totalMissing = 0;

  let tardyMissingMinutes = 0;

  for (let log of tardyLogs) {
    if (log.timestamp) {
      const logTimeDate = log.timestamp.toDate();
      const logTimeHour = logTimeDate.getHours();
      const logTimeMinute = logTimeDate.getMinutes();
      const logTimeMinuteFromMidNight = logTimeHour * 60 + logTimeMinute;
      const diff = logTimeMinuteFromMidNight - startTimeMinuteFromMidNight;

      if (diff > 0) {
        tardyMissingMinutes += diff;
      }
    }
  }

  let earlyPickUpMissingMinutes = 0;

  for (let log of earlyPickUpLogs) {
    if (log.timestamp) {
      const logTimeDate = log.timestamp.toDate();
      const logTimeHour = logTimeDate.getHours();
      const logTimeMinute = logTimeDate.getMinutes();
      const logTimeMinuteFromMidNight = logTimeHour * 60 + logTimeMinute;
      const diff = endTimeMinuteFromMidNight - logTimeMinuteFromMidNight;

      if (diff > 0) {
        earlyPickUpMissingMinutes += diff;
      }
    }
  }
  totalMissing = tardyMissingMinutes + earlyPickUpMissingMinutes;

  if (totalMissing === 0) {
    return 0;
  }

  return totalMissing;
};

export const setPerStudentRows = ({
  logs,
  earlyPickUpLogs,
  selectedSchool,
  students,
  homerooms,
  start,
  end,
}: SetPerStudentRows) => {
  const rows: Row[] = [];
  let rowCount = 0;

  students.forEach((student) => {
    rowCount++; // how many students or rows in total

    const tardyLogCount = filterLogsByDateRange({
      logs: logs,
      SID: student.SID,
      start: start ? start : 0,
      end: end ? end : 999999999999999,
    });

    const earlyPickUpCount = filterEarlyLogsByDateRange({
      earlyPickUpLogs: earlyPickUpLogs,
      SID: student.SID,
      start: start ? start : 0,
      end: end ? end : 999999999999999,
    });

    const filteredEarlyPickUpLogs = earlyPickUpLogs.filter(
      (log) => log.SID === student.SID
    );

    const filteredTardyLogs = logs.filter((log) => log.SID === student.SID);

    const totalMinutesMissing = calculateTotalMinutesMissing({
      earlyPickUpLogs: filteredEarlyPickUpLogs,
      tardyLogs: filteredTardyLogs,
      selectedSchool: selectedSchool,
    });

    rows.push({
      id: student.id,
      SID: student.SID,
      firstName: student.childFirstName,
      lastName: student.childLastName,
      homeroom:
        homerooms.find((homeroom) => homeroom.id === student.homeroom)?.name ||
        "",
      phone: student.primaryPhone,
      logs: filteredTardyLogs,
      pickups: filteredEarlyPickUpLogs,
      count: tardyLogCount,
      earlyPickUpCount: earlyPickUpCount,
      totalMinutesMissing: totalMinutesMissing,
      history: student.SID,
      rowCount: rowCount,
    });
  });

  return rows;
};

const getRecentTime = ({ hours }: { hours: number }): number => {
  return Timestamp.now().seconds - 60 * 60 * hours;
};

export const checkForDuplicates = async (SID: string): Promise<boolean> => {
  const recentTime: number = getRecentTime({ hours: 12 });
  const checkSnapshot = await getRecentLogsByStudent({
    SID: SID,
    recentTime: recentTime,
  });
  return checkSnapshot.size === 0 ? false : true;
};

export const checkDomain = (email: string | null) => {
  if (email) {
    const emailArray = email.split("@");
    return emailArray[1] === "thegatheringplacek12.org" ? true : false;
  }
};

const startingAdmins = [
  "jay@thegatheringplacek12.org",
  "asia@thegatheringplacek12.org",
  "maribel@thegatheringplacek12.org",
  "christina@thegatheringplacek12.org",
  "talent@thegatheringplacek12.org",
  "ryan@thegatheringplacek12.org",
  "dianna@thegatheringplacek12.org",
  "cs-intern-test@thegatheringplacek12.org",
  "esme@thegatheringplacek12.org",
  "robin@thegatheringplacek12.org",
  "jaclynconroywild@thegatheringplacek12.org",
];

const setPermission = (email: string) => {
  return startingAdmins.indexOf(email) > -1 ? "admin" : "staff";
};

export const createUserObj = async (user: any): Promise<User> => {
  // the user is from firebase -> sign in with Google

  const userSnap = await getStaffMember(user.uid);
  let userObj;
  if (!userSnap.exists()) {
    const permission = setPermission(user.email);
    userObj = {
      UID: user.UID,
      name: user.displayName,
      permission: permission,
      email: user.email,
    };
    createStaffMember({
      UID: user.uid,
      name: user.displayName,
      permission: permission,
    });
  } else {
    userObj = {
      UID: userSnap.id,
      name: userSnap.data().name,
      permission: userSnap.data().permission,
      email: "",
    };
  }
  return userObj;
};

export const createDateField = (timestamp: any) => {
  let dt;
  dt = DateTime.fromMillis(timestamp.toMillis());
  const weekNumber = dt.weekNumber;
  const year = dt.year;
  return year.toString() + weekNumber.toString();
};

const setDateString = (log: TardyLogRecord): string | null => {
  if (!log.timestamp) {
    return null;
  }
  const dt = DateTime.fromMillis(log.timestamp.toMillis());
  const date = dt.toLocaleString({
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
  return date;
};

const groupPerDayLogs = (dateObject: any, dateArray: string[]) => {
  const groupedObject: { [key: string]: { count: number; logs: any } } = {};
  dateArray.forEach((date) => {
    const logs = dateObject[date].logs;

    groupedObject[date] = {
      count: dateObject[date].count,
      logs: groupBy(logs, (log) => log.homeroomId),
    };
  });
  return groupedObject;
};

function compareLastName(a: Student, b: Student) {
  // Use toUpperCase() to ignore character casing
  const lastNameA = a.childLastName.toLowerCase();
  const lastNameB = b.childLastName.toLowerCase();

  let comparison = 0;
  if (lastNameA > lastNameB) {
    comparison = 1;
  } else if (lastNameA < lastNameB) {
    comparison = -1;
  }
  return comparison;
}

const sortLogsByLastName = (
  groupedDateObject: any,
  dateArray: string[],
  homeroomsArray: Homeroom[]
) => {
  const groupedAndSorted: any = {};
  dateArray.forEach((date) => {
    const logs = groupedDateObject[date].logs;
    homeroomsArray.forEach((homeroom) => {
      if (logs[homeroom.id]) {
        logs[homeroom.id].sort(compareLastName);
      }
    });
    groupedAndSorted[date] = {
      count: groupedDateObject[date].count,
      logs: logs,
    };
  });
  return groupedAndSorted;
};

export const organizePerDayLogs = (
  tardySnapshot: TardyLogRecordType[],
  homeroomsArray: Homeroom[]
) => {
  const dateObject: { [key: string]: { count: number; logs: any[] } } = {};

  const dateArray: string[] = [];

  tardySnapshot.forEach((log) => {
    const date = setDateString(log);
    if (!date) return;
    if (!dateObject[date]) {
      dateObject[date] = { count: 0, logs: [] };
      dateArray.push(date);
    }
    dateObject[date].count++;
    const timeForPrint = log.timestamp ? log.timestamp.toMillis() : 0;
    dateObject[date].logs.push({
      childFirstName: log.studentFirstName,
      childLastName: log.studentLastName,
      homeroomId: log.homeroom,
      timeForPrint: new Date(timeForPrint).toLocaleTimeString(),
      type: log.type,
    });
  });
  const groupedDateObject = groupPerDayLogs(dateObject, dateArray);
  const groupedAndSortedDateObject = sortLogsByLastName(
    groupedDateObject,
    dateArray,
    homeroomsArray
  );
  return { groupedAndSortedDateObject, dateArray, dateObject };
};

export const getYear = (month: number, year: number) => {
  if (year === 2021 && month < 7) {
    return YEAR.y2021;
  } else if (year === 2021 && month >= 7) {
    return YEAR.y2122;
  } else if (year === 2022 && month < 7) {
    return YEAR.y2122;
  } else if (year === 2022 && month >= 7) {
    return YEAR.y2223;
  } else if (year === 2023 && month < 7) {
    return YEAR.y2223;
  } else if (year === 2023 && month >= 7) {
    return YEAR.y2324;
  } else if (year === 2024 && month < 7) {
    return YEAR.y2324;
  } else {
    return YEAR.y2324;
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
