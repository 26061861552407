import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import PerDayTable from "./PerDayTable";
import { organizePerDayLogs } from "../../libraries/functions";

import { useRecoilValue } from "recoil";
import {
  earlyPickUpLogsAtom,
  homeroomsAtom,
  logsLoadingAtom,
  tardyLogsAtom,
} from "../../recoil/atoms";
import PerDayByDateTable from "./PerDayByDateTable";
import {
  PickUpLog,
  PickUpRecord,
  TardyLogRecord,
  TardyLogRecordType,
} from "../../types/types";

export default function PerDayReport() {
  const [dates, setDates] = useState<string[] | undefined>();
  const [countByDate, setCountByDate] = useState();
  const [showByDate, setShowByDate] = useState(false);
  const [sortedByDate, setSortedByDate] = useState<{
    [key: string]: { count: number; logs: any[] };
  }>({});

  const loading = useRecoilValue(logsLoadingAtom);
  const homerooms = useRecoilValue(homeroomsAtom);

  const tardyLogs = useRecoilValue(tardyLogsAtom);
  const tardyLogsWithType = tardyLogs.map((log) => ({ ...log, type: "tardy" }));
  const pickUpLogs = useRecoilValue(earlyPickUpLogsAtom);
  const earlypickUpLogsWithType = pickUpLogs.map((log) => ({
    ...log,
    type: "early-picked-up",
  }));

  const combinedLogs = [...tardyLogsWithType, ...earlypickUpLogsWithType];

  useEffect(() => {
    if (!combinedLogs || !homerooms) return;

    const getPerDayTardyRows = (combinedLogs: TardyLogRecordType[]) => {
      const { groupedAndSortedDateObject, dateArray, dateObject } =
        organizePerDayLogs(combinedLogs, homerooms);
      setCountByDate(groupedAndSortedDateObject);
      setDates(dateArray);
      setSortedByDate(dateObject);
    };
    getPerDayTardyRows(combinedLogs);

 
  }, [tardyLogs, pickUpLogs, homerooms]);

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowByDate(event.target.checked);
  };


  return (
    <>
      <Typography sx={{ pt: 3, pb: 2 }} variant="h3">
        Per Day Report
      </Typography>
      <FormControlLabel
        control={<Switch checked={showByDate} onChange={handleSwitchChange} />}
        label="Sort By Date"
      />
      {loading && (
        <Box sx={{ display: "block", textAlign: "center" }}>
          <CircularProgress size={50} />
        </Box>
      )}
      {!loading && dates && countByDate && !showByDate && (
        <PerDayTable dates={dates} countByDate={countByDate} />
      )}
      {!loading && dates && countByDate && showByDate && (
        <PerDayByDateTable sortedByDate={sortedByDate} dates={dates} />
      )}
    </>
  );
}
