import { DateTime } from "luxon";
import { useState, useCallback } from "react";
import { Typography } from "@mui/material";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { useRecoilValue } from "recoil";
import { studentsAtom, tardyLogsAtom } from "../recoil/atoms";
import { createDateField } from "../libraries/functions";
import { Student } from "../types/types";

type StudentWithDates = Student & { [key: string]: number | string };

const useBuildPerWeekRows = () => {
  const students = useRecoilValue(studentsAtom);
  const allLogs = useRecoilValue(tardyLogsAtom);
  const buildPerWeekRows = () => {
    if (!students || !allLogs) return;

    const tempStudentsArray: StudentWithDates[] = [];
    students.forEach((student) => {
      //Object.assign to prevent the original student object being modified
      const updatedStudent = Object.assign({}, student);
      const dateLabels: { [key: string]: number } = {};
      allLogs.forEach((tardyLog) => {
        if (student.SID === tardyLog.SID) {
          //found a tardy log for this student
          const dateForLabel = createDateField(tardyLog.timestamp);

          if (dateLabels[dateForLabel]) {
            dateLabels[dateForLabel]++;
          } else {
            // we have not found a tardy log for this kid, for this week
            dateLabels[dateForLabel] = 1;
          }
        }
      });

      tempStudentsArray.push({ ...updatedStudent, ...dateLabels });
    });
    return tempStudentsArray;
  };
  return { buildPerWeekRows };
};

export default useBuildPerWeekRows;
