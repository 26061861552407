import React from "react";
import Navbar from "../components/Navigation/Navbar";
import { Container } from "@mui/material";
import RawDataContainer from "../components/TardyReports/TardyReport";

export const RawDataPage = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{ minHeight: "calc(100vh - 64px)", mt: "64px" }}>
        <RawDataContainer/>
      </Container>
    </>
  );
};
