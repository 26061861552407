import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import HistoryLog from "./HistoryLog";
import { useRecoilValue } from "recoil";
import {
  selectedStudentAtom,
  selectedStudentEarlyPickUpAtom,
  selectedStudentTardyLogsAtom,
} from "../../recoil/atoms";
import PrintContainer from "../Print/PrintContainer";
import HistoryDialogContent from "./HistoryDialogContent";
import { Typography } from "@mui/material";

type Props = {
  handleClose: (value: Boolean) => void;
  open: boolean;
};

const HistoryDialog = ({ handleClose, open }: Props) => {
  const selectedStudentTardyLogs = useRecoilValue(selectedStudentTardyLogsAtom);
  const selectedStudentEarlyPickUps = useRecoilValue(selectedStudentEarlyPickUpAtom);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pr: 2 }}>
        <DialogTitle>Tardy History</DialogTitle>
        <PrintContainer
          tardyLogs={selectedStudentTardyLogs}
          earlyPickUpLogs={selectedStudentEarlyPickUps}
        />
      </Box>

      <HistoryDialogContent
        selectedStudentTardyLogs={selectedStudentTardyLogs}
        selectedStudentEarlyPickUps={selectedStudentEarlyPickUps}
      />
    </Dialog>
  );
};

export default HistoryDialog;
