import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { HandleDetailsClickFunction, Row } from "../../types/types";
type Props={
    openHistory:HandleDetailsClickFunction;
    row:Row;

}
export default function StudentCard({ openHistory, row }:Props) {
  const message = row.logs ? "History" : "This user hasn't been late";
  const handleClick = () => {
    openHistory(row);
  };

  return (
    <Card sx={{ minWidth: 275, marginBottom: "25px" }}>
      <CardContent>
        <Typography sx={{ fontSize: 15 }} color="text.secondary" gutterBottom>
          {" "}
          {`FIRST NAME: ${row.firstName}`}
        </Typography>
        <Typography sx={{ fontSize: 15 }} color="text.secondary" gutterBottom>
          {" "}
          {`LAST NAME: ${row.lastName}`}
        </Typography>
        <Typography sx={{ fontSize: 15 }} color="text.secondary" gutterBottom>
          {" "}
          {`TARDIES: ${row.count}`}
        </Typography>
        <Typography sx={{ fontSize: 15 }} color="text.secondary" gutterBottom>
          {`HOMEROOM: ${row.homeroom}`}
        </Typography>
        <Typography sx={{ fontSize: 15 }} color="text.secondary" gutterBottom>
          PHONE:
          <a href={`tel:${row.phone}`}> {row.phone}</a>
        </Typography>
        <Button
          variant="outlined"
          fullWidth
          onClick={handleClick}
          disabled={row.logs ? false : true}
        >
          {message}
        </Button>
      </CardContent>
    </Card>
  );
}
