import { useContext, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  ListItem,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "../../providers/AuthProvider";
import Weblinks from "./Weblinks";
import YearSelect from "./YearSelect";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import useWindowDimensions from "../../libraries/functions";

const linkStyle = {
  color: "#333333",
  textDecoration: "none",
  fontWeight: 400,
  marginLeft: 8,
  marginRight: 8,
};

const Navbar = () => {
  const { logout, currentAuthUser } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { width } = useWindowDimensions();
  const collapse = width > 1000 ? false : true;
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setState] = useState(false);

  const toggleDrawer = () => {
    setState(!open);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography sx={{ flexGrow: 1, fontWeight: 700 }}>
            Tardy App
          </Typography>
          {currentAuthUser && (
            <>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>

              {open && (
                <Drawer anchor="right" open={open}>
                  <Box
                    sx={{
                      p: 2,
                      height: 1,
                    }}
                  >
                    <IconButton sx={{ mb: 2 }}>
                      <CloseIcon onClick={toggleDrawer} />
                    </IconButton>

                    <Divider sx={{ mb: 2 }} />
                    <Box sx={{ mb: 2 }}>
                      <ListItem  sx={{color: "black",}}>
                        <Link style={linkStyle} to="/">
                          Tardy Sign In
                        </Link>
                      </ListItem  >
                      <Divider sx={{ mb: 2 }} />
                      <ListItem sx={{color: "ff00",}}>
                        <Link style={linkStyle} to="/earlypickup">
                          Early Pick Up
                        </Link>
                      </ListItem>
                      <Divider sx={{ mb: 2 }} />

                      {currentAuthUser && <Weblinks />}

                      <Divider sx={{ mb: 2 }} />
                      {currentAuthUser && (
                        <>
                          <ListItem sx={{color: "ff00",}}>
                            <YearSelect />
                          </ListItem>
                          <Divider sx={{ mb: 2 }} />
                          <ListItem sx={{color: "ff00",}}>
                            <Button
                              className="webLink"
                              color="inherit"
                              onClick={logout}
                              sx={{ fontWeight: 700 }}
                            >
                              LogOut
                            </Button>
                          </ListItem>
                        </>
                      )}

                      {!currentAuthUser && location.pathname !== "/login" && (
                        <>
                          <Button
                            className="webLink"
                            color="inherit"
                            onClick={() => navigate("/login")}
                            sx={{ fontWeight: 700 }}
                          >
                            Log In
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                </Drawer>
              )}
            </>
          )}

          {!currentAuthUser && location.pathname !== "/login" && (
            <>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={toggleDrawer}
              >
                <MenuIcon />
              </IconButton>
              {open && (
                <Drawer anchor="right" open={open}>
                  <IconButton sx={{ mb: 2 }}>
                    <CloseIcon onClick={toggleDrawer} />
                  </IconButton>
                  <Divider sx={{ mb: 2 }} />
                  <>
                    <ListItem >
                      <Link style={linkStyle} to="/"     >
                        Tardy Sign In
                      </Link>
                    </ListItem>
                    <Divider sx={{ mb: 2 }} />
                    <ListItem sx={{color: "ff00",}}>
                      <Link style={linkStyle} to="/earlypickup">
                        Early Pick Up
                      </Link>
                    </ListItem>
                    <Divider sx={{ mb: 2 }} />
                  </>
                  <ListItem sx={{color: "ff00",}}>
                    <Button
                      className="webLink"
                      color="inherit"
                      onClick={() => navigate("/login")}
                      sx={{ fontWeight: 700 }}
                    >
                      Log In
                    </Button>
                  </ListItem>
                </Drawer>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
