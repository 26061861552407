import { useEffect, useContext } from "react";
import useGetDocs from "./useGetDocs";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { staffAtom, loggedInStaffAtom } from "../recoil/staffAtoms";
import { PickUpRecord, Staff, TardyLogRecord } from "../types/types";
import { AuthContext } from "../providers/AuthProvider";
import { SchoolRecord } from "../types/types";
import {
  parseStaffResponse,
  parseTardyLogResponse,
  parseEarlyPickUpLogResponse,
} from "../libraries/parsers";
import { DateTime } from "luxon";
import { getYear } from "../libraries/functions";
import {
  selectedYearAtom,
  tardyLogsAtom,
  selectedSchoolAtom,
  earlyPickUpLogsAtom,
} from "../recoil/atoms";

const useBootstrapEffect = () => {
  const { sendRequest: getDocs } = useGetDocs();
  const { currentAuthUser } = useContext(AuthContext);
  const staff = useRecoilValue(staffAtom);
  const setStaff = useSetRecoilState<Staff[]>(staffAtom);
  const setLoggedInStaff = useSetRecoilState<Staff | null>(loggedInStaffAtom);
  const selectedYear = useRecoilValue(selectedYearAtom);
  const setTardyLogs = useSetRecoilState(tardyLogsAtom);
  const setEarlyPickUpLogs = useSetRecoilState(earlyPickUpLogsAtom);
  const setSelectedSchool = useSetRecoilState(selectedSchoolAtom);

  useEffect(() => {
    const getStaff = async () => {
      const response = await getDocs<Staff>({ col: "staff" });
      if (response) {
        setStaff(parseStaffResponse(response));
      }
    };
    getStaff();
  }, [setStaff, getDocs]);

  useEffect(() => {
    const getSelectedSchool = async () => {
      const response = await getDocs<SchoolRecord>({ col: "schools" });
      if (response) {
        const activeSchool = response.find(
          (school) => school.name === "The Gathering Place"
        );
        if (!activeSchool) return;
        setSelectedSchool(activeSchool);
      }
    };
    getSelectedSchool();
  }, [getDocs, setSelectedSchool]);

  useEffect(() => {
    if (!currentAuthUser || !staff) return;

    const filteredStaff = staff.filter(
      (staffMember) => staffMember.email === currentAuthUser.email
    );
    // console.log(filteredStaff);
    if (filteredStaff.length === 1) {
      setLoggedInStaff(filteredStaff[0]);
    } else {
      //generate message
      console.error(
        "[Hook] useBootstrapEffect - filtered Staff, no DB user found or multiple DB users found",
        currentAuthUser.email,
        filteredStaff.length
      );
    }
  }, [currentAuthUser, staff, setLoggedInStaff]);

  useEffect(() => {
    if (!selectedYear) return;

    const getTardyLogs = async () => {
      const results = await getDocs<TardyLogRecord>({
        col: "tardyLogs",
        config: { orderBy: ["timestamp", "desc"] },
      });
      // console.log("tardy log Results",results)

      // TODO: restructure timestamps to be iso format
      const tempLogs: TardyLogRecord[] = [];
      results.forEach((log) => {
        if (!log.timestamp) return;
        const dt = DateTime.fromMillis(log.timestamp.toMillis());
        const year = getYear(dt.month, dt.year);
        if (selectedYear === year) {
          tempLogs.push({ ...log, year: year });
        }
      });

      setTardyLogs(parseTardyLogResponse(tempLogs));
    };

    getTardyLogs();
  }, [getDocs, selectedYear, setTardyLogs]);

  useEffect(() => {
    if (!selectedYear) return;

    const getEarlyPickUpLogs = async () => {
      const results = await getDocs<PickUpRecord>({
        col: "earlyPickUpLogs",
        // config: { orderBy: ["timestamp", "desc"] },
      });
      // console.log("results",results);

      // TODO: restructure timestamps to be iso format
      const tempLogs: PickUpRecord[] = [];
      results.forEach((log) => {
        if (!log.timestamp) return;
        const dt = DateTime.fromMillis(log.timestamp.toMillis());
        const year = getYear(dt.month, dt.year);
        if (selectedYear === year) {
          tempLogs.push({ ...log, year: year });
        }
      });

      setEarlyPickUpLogs(parseEarlyPickUpLogResponse(tempLogs));
    };

    getEarlyPickUpLogs();
  }, [getDocs, selectedYear, setEarlyPickUpLogs]);
};

export default useBootstrapEffect;
