import { atom } from "recoil";
import { Staff, StaffFromAPI } from "../types/types";

export const staffAtom = atom<Staff[]>({
  key: "staff",
  default: [],
});

export const loggedInStaffAtom = atom<Staff | null>({
  key: "loggedInStaff",
  default: null,
});

export const staffResetAtom = atom({
  key: "staffReset",
  default: false,
});

export const staffFromAPIAtom = atom<StaffFromAPI[]>({
  key: "staffFromAPI",
  default: [],
});
