import React, { FormEvent, Fragment } from "react";
import {
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import SubmissionDialog from "./SubmissionDialog";
import SignInForm from "./SignInForm";
import { checkForDuplicates } from "../../libraries/functions";
import useAddDoc from "../../hooks/useAddDoc";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  postAtom,
  checkedAtom,
  verifySubmissionAtom,
  switchAtom,
} from "../../recoil/atoms";
import { blankTardyForm } from "../../libraries/blankForms";
import { Timestamp } from "firebase/firestore";

export default function SignInFormContainer() {
  const [post, setPost] = useRecoilState(postAtom);
  const [checked, setChecked] = useRecoilState(checkedAtom);
  const [verifySubmission, setVerifySubmission] =
    useRecoilState(verifySubmissionAtom);

    const [languageSwitchToSpanish,setLanguageSwitchToSpanish] =useRecoilState(switchAtom);

  const { sendRequest: addDoc } = useAddDoc();

  const submitForm = async (e: FormEvent) => {
    e.preventDefault();

    const duplicateExists = await checkForDuplicates(post.SID);

    if (duplicateExists) {
      resetForm();
      return;
    }
    // console.log(post);
    await addDoc({
      col: "tardyLogs",
      data: {
        ...post,
        timestamp: Timestamp.now(),
        seconds: Timestamp.now().seconds,
      },
    });
    resetForm();
  };

  const resetForm = () => {
    // setPost(blankTardyForm);
    setChecked(false);
    setVerifySubmission(true);
  };
  

  return (
    <Fragment>
      {languageSwitchToSpanish && (
         <Typography sx={{ pt: 3, pb: 2 }} variant="h3">
       Iniciar sesión tarde
       </Typography>
      )}
      {!languageSwitchToSpanish && (<Typography sx={{ pt: 3, pb: 2 }} variant="h3">
        Late Sign In
      </Typography>)}
      
      <FormGroup>
        <FormControlLabel control={<Switch checked={languageSwitchToSpanish} onClick={()=>{setLanguageSwitchToSpanish(!languageSwitchToSpanish)}}/>} label="Spanish" />
      </FormGroup>
      
      <Paper sx={{ padding: 2 }}>
        {post && <SignInForm submitForm={submitForm} />}
      </Paper>
      {verifySubmission && <SubmissionDialog />}
    </Fragment>
  );
}
