import React, { useState } from "react";
import { Grid, Typography, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Button } from "@mui/material";
import { DateTime } from "luxon";
import { selectedSchoolAtom } from "../../recoil/atoms";
import useUpdateDoc from "../../hooks/useUpdateDoc";
import { useRecoilValue } from "recoil";
import { useEffect } from "react";

const ManageSchoolHours = () => {
  const selectedSchool = useRecoilValue(selectedSchoolAtom);
  const { sendRequest: updateDoc } = useUpdateDoc();

  const [startTime, setStartTime] = useState<DateTime | null>(null);
  const [endTime, setEndTime] = useState<DateTime | null>(null);

  useEffect(() => {
    if (!selectedSchool) return;
    setStartTime(DateTime.fromISO(selectedSchool.startTime));
    setEndTime(DateTime.fromISO(selectedSchool.endTime));
  }, [selectedSchool]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!selectedSchool) return;
    // console.log(selectedSchool);
    updateDoc({
      col: "schools",
      data: { startTime: startTime?.toISO(), endTime: endTime?.toISO() },
      id: selectedSchool.id,
    });
  };

  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Set School Timing
      </Typography>
      <form onSubmit={handleSubmit}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <TimePicker
            label="Start Time"
            value={startTime}
            onChange={(newValue) => {
              setStartTime(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <TimePicker
            label="End Time"
            value={endTime}
            onChange={(newValue) => {
              setEndTime(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Grid item xs={12}>
          <Button type="submit" variant="contained">
            Submit
          </Button>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default ManageSchoolHours;
