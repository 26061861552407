import React from "react";
import { Button, DialogTitle, Dialog } from "@mui/material";
import { useRecoilState } from "recoil";
import { postAtom } from "../../recoil/atoms";
import { blankEarlyPickUpForm } from "../../libraries/blankForms";
import { checkedAtom, verifySubmissionAtom } from "../../recoil/atoms";

export default function SubmissionDialog() {
  const [post, setPost] = useRecoilState(postAtom);
  const [verifySubmission, setVerifySubmission] =
    useRecoilState(verifySubmissionAtom);
  const [checked, setChecked] = useRecoilState(checkedAtom);

  const handleClose = () => {
    //reset form
    setPost(blankEarlyPickUpForm);
    setVerifySubmission(false);
    setChecked(false);
  };

  return (
    <Dialog onClose={handleClose} open={verifySubmission}>
      <DialogTitle>
        Your Submission For {post.studentFirstName + " " + post.studentLastName}{" "}
        Has Been Recorded
      </DialogTitle>

      <Button
        sx={{ margin: 2 }}
        variant="contained"
        onClick={() => handleClose()}
      >
        Submit Another Response
      </Button>
    </Dialog>
  );
}
