import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { FormControlLabel, TextField, Box, FormControl, RadioGroup, Radio } from "@mui/material";
// import { YEAR } from "../../types/types";
import { yearsObj } from "../../libraries/objects";
import { useRecoilValue } from "recoil";
import { selectedYearAtom } from "../../recoil/atoms";


type Props={
    setStart: Dispatch<SetStateAction<number>>;
  setEnd:Dispatch<SetStateAction<number>> ;
 
}

type MyDate = {
  ts: number;
};

export const PerStudentTimeFilter = ({ setStart, setEnd }: Props) => {
  const selectedYear = useRecoilValue(selectedYearAtom);
  const [yearStart, setYearStart] = useState(
    new Date(`7/1/${yearsObj[selectedYear].start}`).getTime()
  );
  const [yearEnd, setYearEnd] = useState(new Date(`6/30/${yearsObj[selectedYear].end}`).getTime());
  const [semesterTwoStart, setSemesterTwoStart] = useState(
    new Date(`1/1/${yearsObj[selectedYear].end}`).getTime()
  );
  const [chosenStartDate, setChosenStartDate] = useState<MyDate | null>({
    ts: yearStart,
  });
  const [chosenEndDate, setChosenEndDate] = useState<MyDate | null>({
    ts: yearEnd,
  });

  useEffect(() => {
    setYearStart(new Date(`7/1/${yearsObj[selectedYear].start}`).getTime());
    setYearEnd(new Date(`6/30/${yearsObj[selectedYear].end}`).getTime());
    setSemesterTwoStart(new Date(`1/1/${yearsObj[selectedYear].end}`).getTime());
    setChosenStartDate({ ts: new Date(`7/1/${yearsObj[selectedYear].start}`).getTime() });
    setChosenEndDate({ ts: new Date(`6/30/${yearsObj[selectedYear].end}`).getTime() });
  }, [selectedYear]);

  const handleYear = () => {
    setChosenStartDate({ ts: new Date(yearStart).getTime() });
    setChosenEndDate({ ts: new Date(yearEnd).getTime() });
    setStart(yearStart);
    setEnd(yearEnd);
  };
  const handleSemesterOne = () => {
    setChosenStartDate({ ts: new Date(yearStart).getTime() });
    setChosenEndDate({ ts: new Date(semesterTwoStart).getTime() });
    setStart(yearStart);
    setEnd(semesterTwoStart);
  };

  const handleSemesterTwo = () => {
    setChosenStartDate({ ts: new Date(semesterTwoStart).getTime() });
    setChosenEndDate({ ts: new Date(yearEnd).getTime() });
    setStart(semesterTwoStart);
    setEnd(yearEnd);
  };

  const handleStartDate = (date: MyDate | null) => {
    setChosenStartDate(date);
    setStart(date?.ts ?? 0);
  };

  const handleEndDate = (date: MyDate | null) => {
    setChosenEndDate(date);
    setEnd(date?.ts ?? 0);
  };

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    const chosenFilter = e.target.value;
    switch (chosenFilter) {
      case "Year":
        handleYear();
        break;
      case "Semester 1":
        handleSemesterOne();
        break;
      case "Semester 2":
        handleSemesterTwo();
        break;

      default:
        handleYear();
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <FormControl style={{ padding: "16px" }}>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Year"
          name="radio-buttons-group"
          onChange={handleFilter}
        >
          <FormControlLabel value="Year" control={<Radio />} label="Year" />
          <FormControlLabel value="Semester 1" control={<Radio />} label="Semester 1" />
          <FormControlLabel value="Semester 2" control={<Radio />} label="Semester 2" />

          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              label="Start date"
              value={new Date(chosenStartDate?.ts ?? yearStart)}
              onChange={(date) =>
                date !== null ? handleStartDate({ ts: date.getTime() }) : handleStartDate(null)
              }
              // couldn't solve this null
              renderInput={(params) => (
                <TextField {...params} size="small" sx={{ width: "180px" }} />
              )}
              disableMaskedInput={true}
            />

            <DatePicker
              label="End date"
              value={new Date(chosenEndDate?.ts ?? yearEnd)}
              onChange={(date) =>
                date !== null ? handleEndDate({ ts: date.getTime() }) : handleEndDate(null)
              }
              renderInput={(params) => (
                <TextField {...params} size="small" sx={{ width: "180px", mx: "4px" }} />
              )}
              disableMaskedInput={true}
            />
          </LocalizationProvider>
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
