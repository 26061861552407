import React from "react";
import { Box,FormControl,InputLabel,MenuItem, OutlinedInput } from "@mui/material";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import { selectedYearAtom } from "../../recoil/atoms";
import { useRecoilState } from "recoil";
import { YEAR } from "../../types/types";

export default function YearSelect() {
  const [selectedYear, setSelectedYear] = useRecoilState(selectedYearAtom);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedYear(event.target.value as YEAR);
  };

  return (
    <Box sx={{ color: "#fff", borderColor: "#fff" }}>
    <FormControl>
      <InputLabel id ="year-select-label" >Select Year</InputLabel>
      <Select
        id="year-select"
        value={selectedYear}
        onChange={handleChange}
        labelId="year-select-label"
        label="Select Year"
        sx={{
          color: "ff00",
          // fontWeight: 800,
        
          maxWidth: "30vw",
          minWidth:"13vw",
        }}

       

      >
        <MenuItem value={YEAR.y2122}>SY 21 - 22</MenuItem>
        <MenuItem value={YEAR.y2223}>SY 22 - 23</MenuItem>
        <MenuItem value={YEAR.y2324}>SY 23 - 24</MenuItem>
      </Select>
      </FormControl>
    </Box>
  );
}
