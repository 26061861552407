import { LogType } from "../types/types";

export const blankTardyForm: LogType = {
  caregiverFirstName: "",
  caregiverLastName: "",
  studentFirstName: "",
  studentLastName: "",
  SID: "",
  homeroom: "",
  reason: "",
  seconds: 0,
  studentId: "",
  year: "",
  timestamp: null,
};

export const blankEarlyPickUpForm: LogType = {
  caregiverFirstName: "",
  caregiverLastName: "",
  studentFirstName: "",
  studentLastName: "",
  SID: "",
  homeroom: "",
  reason: "",
  seconds: 0,
  studentId: "",
  year: "",
  timestamp: null,
};
