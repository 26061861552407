import React, { FormEvent, Fragment } from "react";
import { Paper, Typography,FormGroup,FormControlLabel,Switch } from "@mui/material";
import SubmissionDialog from "./SubmissionDialog";
import { checkForDuplicates } from "../../libraries/functions";
import useAddDoc from "../../hooks/useAddDoc";
import { useRecoilState } from "recoil";
import {
  postAtom,
  checkedAtom,
  verifySubmissionAtom,
  switchAtom,
} from "../../recoil/atoms";
import EarlyPickUpForm from "./EarlyPickUpForm";
import { Timestamp } from "firebase/firestore";

export default function EarlyPickUpContainer() {
  const [post, setPost] = useRecoilState(postAtom);
  const [checked, setChecked] = useRecoilState(checkedAtom);
  const [verifySubmission, setVerifySubmission] =
    useRecoilState(verifySubmissionAtom);
    const [languageSwitchToSpanish,setLanguageSwitchToSpanish] =useRecoilState(switchAtom);

  const { sendRequest } = useAddDoc();

  const submitForm = async (e: FormEvent) => {
    e.preventDefault();

    const duplicateExists = await checkForDuplicates(post.SID);

    if (duplicateExists) {
      resetForm();
      return;
    }

    sendRequest({
      col: "earlyPickUpLogs",
      data: {
        ...post,
        timestamp: Timestamp.now(),
        seconds: Timestamp.now().seconds,
      },
    });
    resetForm();
  };

  const resetForm = () => {
    // setPost(blankEarlyPickUpForm);
    setChecked(false);
    setVerifySubmission(true);
  };
  return (
    <Fragment>
      {
        languageSwitchToSpanish &&(
          <Typography sx={{ pt: 3, pb: 2 }} variant="h3">
       Recogida anticipada Cerrar sesión
      </Typography>
        )

      }
      {
          !languageSwitchToSpanish && (
            <Typography sx={{ pt: 3, pb: 2 }} variant="h3">
            Early Pick Up Sign Out
          </Typography>
          )
      }

      <FormGroup>
        <FormControlLabel control={<Switch checked={languageSwitchToSpanish} onClick={()=>{setLanguageSwitchToSpanish(!languageSwitchToSpanish)}}/>} label="Spanish" />
      </FormGroup>
   
      <Paper sx={{ padding: 2 }}>
        {post && <EarlyPickUpForm submitForm={submitForm} />}
      </Paper>
      {verifySubmission && <SubmissionDialog />}
    </Fragment>
  );
}
