import React from "react";
import { Container } from "@mui/material";
import NavBar from "../components/Navigation/Navbar";
import PerWeekContainer from "../components/TardyReports/PerWeekContainer";

export default function PerWeekReport() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "calc(100vh - 64px)", mt: "64px" }}>
        <PerWeekContainer />
      </Container>
    </>
  );
}
