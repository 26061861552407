import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { Timestamp } from "firebase/firestore";
// import { ChangeEvent } from "react";
type FIREBASE_ID = string;

export type BaseRecord = {
  id: FIREBASE_ID;
  lastUpdatedAt: Timestamp | null;
  createdAt: Timestamp | null;
  timestamp: Timestamp | null;
};

export type Staff = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  permissions: string[];
  homerooms: string[];
};

export type Homeroom = {
  [id: string]: string;
  name: string;
  grade: string;
};

export type Student = {
  id: string;
  childFirstName: string;
  childLastName: string;
  enrollStatus: string;
  homeroom: string;
  SID: string;
  label: string;
  key: string;
  SPED: string;
  primaryPhone: string;
};

export type Row = {
  id: string;
  SID: string;
  firstName: string;
  lastName: string;
  homeroom: string;
  phone: string;
  count: number;
  earlyPickUpCount: number;
  history: string;
  rowCount: number;
  logs: TardyLogRecord[];
  pickups: PickUpRecord[];
  totalMinutesMissing: number;
};

export type StaffFromAPI = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  homerooms: string[];
};

export type DataGridColumn = {
  field: string;
  headerName: string;
  minWidth: number;
  headerClassName: string;
  cellClassName: string;
  flex: number;
  valueFormatter?: (params: GridValueFormatterParams) => string;
};

export type LogType = {
  SID: string;
  caregiverFirstName: string;
  caregiverLastName: string;
  homeroom: string;
  reason: string;
  seconds: number;
  studentFirstName: string;
  studentLastName: string;
  studentId: string;
  timestamp: Timestamp | null;
  year: string;
  [key: string]: string | number | null | Timestamp;
};

export type TardyLog = {
  SID: string;
  caregiverFirstName: string;
  caregiverLastName: string;
  homeroom: string;
  reason: string;
  seconds: number;
  studentFirstName: string;
  studentLastName: string;
  studentId: string;
  timestamp: Timestamp | null;
  year: string;
};

export type TardyLogWithType = {
  SID: string;
  caregiverFirstName: string;
  caregiverLastName: string;
  homeroom: string;
  reason: string;
  seconds: number;
  studentFirstName: string;
  studentLastName: string;
  studentId: string;
  timestamp: Timestamp | null;
  year: string;
  type:string;
};

export type PickUpLog = {
  SID: string;
  caregiverFirstName: string;
  caregiverLastName: string;
  homeroom: string;
  reason: string;
  seconds: number;
  studentFirstName: string;
  studentLastName: string;
  studentId: string;
  timestamp: Timestamp | null;
  year: string;
};

export type TardyLogRecord = TardyLog & BaseRecord;
export type TardyLogRecordType = TardyLogWithType & BaseRecord;
export type PickUpRecord = PickUpLog & BaseRecord;

export type DocType = {
  SID: string;
  SPED: string;
  childFirstName: string;
  childLastName: string;
  enrollStatus: string;
  homeroom: string;
  id: string;
  primaryPhone: string;
};

export type HandleDetailsClickFunction = (data: Row) => void;

export type User = {
  UID: string;
  name: string;
  permission: string;
  email: string;
};

export enum YEAR {
  y2021 = "20-21",
  y2122 = "21-22",
  y2223 = "22-23",
  y2324 = "23-24",
}

export type School = {
  name: string;
  startTime: string;
  endTime: string;
};

export type SchoolRecord = School & BaseRecord;

export type RawDataRow = LogType &
  BaseRecord & {
    logType: string;
  };
