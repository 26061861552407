import {
  Homeroom,
  Student,
  Staff,
  TardyLogRecord,
  PickUpRecord,
} from "../types/types";

export const parseHomeroomResponse = (response: Homeroom[]): Homeroom[] =>
  response.map((homeroom: Homeroom) => ({
    id: homeroom?.id ?? "",
    name: homeroom?.name ?? "",
    grade: homeroom?.grade ?? "",
  }));

export const parseStudentResponse = (response: Student[]): Student[] =>
  response.map((student: Student) => ({
    id: student?.id ?? "",
    childFirstName: student?.childFirstName ?? "",
    childLastName: student?.childLastName ?? "",
    enrollStatus: student?.enrollStatus ?? "",
    homeroom: student?.homeroom ?? "",
    SID: student?.SID ?? "",
    label: student?.label ?? "",
    key: student?.key ?? "",
    primaryPhone: student?.primaryPhone ?? "",
    SPED: student?.SPED ?? "",
  }));

export const parseStaffResponse = (response: Staff[]): Staff[] =>
  response.map((staff: Staff) => ({
    id: staff?.id ?? "",
    firstName: staff?.firstName ?? "",
    lastName: staff?.lastName ?? "",
    email: staff?.email ?? "",
    permissions: staff?.permissions ?? [],
    homerooms: staff?.homerooms ?? [],
  }));

export const parseTardyLogResponse = (
  response: TardyLogRecord[]
): TardyLogRecord[] =>
  response.map((record: TardyLogRecord) => ({
    SID: record?.SID ?? "",
    caregiverFirstName: record?.caregiverFirstName ?? "",
    caregiverLastName: record?.caregiverLastName ?? "",
    homeroom: record?.homeroom ?? "",
    reason: record?.reason ?? "",
    seconds: record?.seconds ?? 0,
    studentFirstName: record?.studentFirstName ?? "",
    studentLastName: record?.studentLastName ?? "",
    studentId: record?.studentId ?? "",
    timestamp: record?.timestamp ?? null,
    createdAt: record?.timestamp ?? null,
    lastUpdatedAt: record?.timestamp ?? null,
    year: record?.year ?? "",
    id: record?.id ?? "",
  }));

export const parseEarlyPickUpLogResponse = (
  response: PickUpRecord[]
): PickUpRecord[] =>
  response.map((record: PickUpRecord) => ({
    SID: record?.SID ?? "",
    caregiverFirstName: record?.caregiverFirstName ?? "",
    caregiverLastName: record?.caregiverLastName ?? "",
    homeroom: record?.homeroom ?? "",
    reason: record?.reason ?? "",
    seconds: record?.seconds ?? 0,
    studentFirstName: record?.studentFirstName ?? "",
    studentLastName: record?.studentLastName ?? "",
    studentId: record?.studentId ?? "",
    timestamp: record?.timestamp ?? null,
    createdAt: record?.timestamp ?? null,
    lastUpdatedAt: record?.timestamp ?? null,
    year: record?.year ?? "",
    id: record?.id ?? "",
  }));
