import { DialogContent, DialogTitle } from "@mui/material";
import HistoryLog from "./HistoryLog";
import { Typography } from "@mui/material";
import { TardyLogRecord } from "../../types/types";

type Props = {
  selectedStudentTardyLogs: TardyLogRecord[];
  selectedStudentEarlyPickUps: TardyLogRecord[];
};

export default function HistoryDialogContent({
  selectedStudentTardyLogs,
  selectedStudentEarlyPickUps,
}: Props) {
  return (
    <>
      <DialogContent>
        {selectedStudentTardyLogs.length > 0 ? (
          selectedStudentTardyLogs.map((log) => <HistoryLog key={log.id} log={log} />)
        ) : (
          <Typography>No tardy record this year.</Typography>
        )}
      </DialogContent>
      <DialogTitle>Early Pick Up History</DialogTitle>

      <DialogContent>
        {selectedStudentEarlyPickUps.length > 0 ? (
          selectedStudentEarlyPickUps.map((log) => <HistoryLog key={log.id} log={log} />)
        ) : (
          <Typography>No early pick up record this year.</Typography>
        )}
      </DialogContent>
    </>
  );
}
