import React from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useRecoilState, useRecoilValue } from "recoil";
import { checkedAtom, switchAtom } from "../../recoil/atoms";

const EarlyPickUpConfirmation = () => {
  const [checked, setChecked] = useRecoilState(checkedAtom);
  const languageSpanish=useRecoilValue(switchAtom);
  const handleChange = () => {
    setChecked((prevValue) => !prevValue);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox required checked={checked} onClick={handleChange} />}
        label={!languageSpanish? "I understand that when my child is picked up early, they miss afternoon circle, a critical part of the school day where children build community, develop social-emotional skills, and prepare for the following day.Leaving early can lead to my child feeling less connected to school and their peers, which impacts their learning.":"Entiendo que cuando recojan a mi hijo temprano,se pierden el círculo de la tarde, una parte fundamental del día escolar donde los niños construyen comunidad,desarrollar habilidades socioemocionales y prepararse para el día siguiente.Salir temprano puede hacer que mi hijo se sienta menos conectado con la escuela y sus compañeros.lo que impacta su aprendizaje."}
      />
    </FormGroup>
  );
};

export default EarlyPickUpConfirmation
