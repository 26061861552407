import { RecoilRoot } from "recoil";
import AppBootstrap from "./AppBootstrap";
import AuthProvider from "./providers/AuthProvider";
import "./print.css";

function App() {
  return (
    <AuthProvider>
      <RecoilRoot>
        <AppBootstrap />
      </RecoilRoot>
    </AuthProvider>
  );
}

export default App;
