import { Box, CircularProgress, Dialog, Typography, Button } from "@mui/material";
import { PerStudentTimeFilter } from "./PerStudentTimeFilter";
import React, { useState, useEffect } from "react";
import useWindowDimensions from "../../libraries/useWindowDimensions";
import HistoryDialog from "./HistoryDialog";
import PerStudentCards from "./PerStudentCards";
import { PerStudentTable } from "./PerStudentTable";

import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  homeroomsAtom,
  logsLoadingAtom,
  selectedSchoolAtom,
  selectedStudentAtom,
  sortedEarlyPickUpAtom,
  sortedTardyLogsAtom,
  studentsAtom,
} from "../../recoil/atoms";
import { setPerStudentRows, sortLogsByStudent } from "../../libraries/functions";

import { Row } from "../../types/types";

export const PerStudentContainer = () => {
  const sortedTardyLogs = useRecoilValue(sortedTardyLogsAtom);
  const sortedEarlyPickUpLogs = useRecoilValue(sortedEarlyPickUpAtom);
  const selectedSchool = useRecoilValue(selectedSchoolAtom);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<Row[]>();
  const { width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);
  const homerooms = useRecoilValue(homeroomsAtom);
  const students = useRecoilValue(studentsAtom);
  const logsLoading = useRecoilValue(logsLoadingAtom);
  const setSelectedStudent = useSetRecoilState(selectedStudentAtom);
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(2664575200000);

  useEffect(() => {
    const handleResize = () => {
      // if (width > 900) {
      //   setIsMobile(false);
      // }
      // if (width <= 900) {
      //   setIsMobile(true);
      // }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    if (!homerooms || !students) {
      return;
    }
    const logsObject = sortLogsByStudent(sortedTardyLogs);
    const earlyPickUpLogsObject = sortLogsByStudent(sortedEarlyPickUpLogs);

    const logsArray = Object.values(logsObject).flat();
    //console.log("logsArray", logsArray);
    const earlyPickUpLogsArray = Object.values(earlyPickUpLogsObject).flat();

    const getPerChildRows = async () => {
      // const logs = sortLogsByStudent(sortedTardyLogs );
      const tempRows = setPerStudentRows({
        homerooms,
        logs: logsArray,
        earlyPickUpLogs: earlyPickUpLogsArray,
        selectedSchool: selectedSchool,
        students,
        start,
        end,
      });
      // console.log("tempRows", tempRows); // all the rows
      setRows(tempRows);
    };
    getPerChildRows();
  }, [homerooms, students, sortedTardyLogs, start, end]);

  const handleClose = () => {
    setSelectedStudent(null);
    setOpen(false);
  };

  const handleDetailsClick = (data: Row) => {
    // console.log("--->", data);
    setSelectedStudent({
      id: data.id,
      childFirstName: data.firstName,
      childLastName: data.lastName,
      enrollStatus: "",
      homeroom: data.homeroom,
      SID: data.SID,
      label: "",
      key: "",
      SPED: "",
      primaryPhone: data.phone,
    });
    setOpen(true);
  };

  return (
    <>
      <Typography sx={{ pt: 3, pb: 2 }} variant="h3">
        Tardies Per Kid
      </Typography>

      <PerStudentTimeFilter setStart={setStart} setEnd={setEnd} />

      {logsLoading && (
        <Box sx={{ display: "block", textAlign: "center" }}>
          <CircularProgress size={50} />
        </Box>
      )}
      {!logsLoading && rows && !isMobile && (
        <PerStudentTable handleDetailsClick={handleDetailsClick} rows={rows} />
      )}
      {!logsLoading && rows && isMobile && (
        <PerStudentCards rows={rows} handleDetailsClick={handleDetailsClick} />
      )}

      <HistoryDialog handleClose={handleClose} open={open}></HistoryDialog>
    </>
  );
};
