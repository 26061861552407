import { Link } from "react-router-dom";
import { loggedInStaffAtom } from "../../recoil/staffAtoms";
import { useRecoilValue } from "recoil";
import { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import { Divider, ListItem } from "@mui/material";

function Weblinks() {
  const loggedInStaff = useRecoilValue(loggedInStaffAtom);

  // const {currentAuthUser} = useAuth(AuthContext)
  const { currentAuthUser } = useContext(AuthContext);

  const linkStyle = {
    color: "#333333",
    textDecoration: "none",
    fontWeight: 400,
    marginLeft: 8,
    marginRight: 8,
  };

  return (
    <>
      {/* can set permissions here */}
      {currentAuthUser && (
        <>
         <ListItem>
          <Link style={linkStyle} to="/settings">
            Settings
          </Link>
          </ListItem>
          <Divider sx={{ mb: 2 }} />
          <ListItem>
          <Link style={linkStyle} to="/perStudentReport">
            Per Kid
          </Link>
          </ListItem>
          <Divider sx={{ mb: 2 }} />
          <ListItem>
          <Link style={linkStyle} to="/perDayReport">
            Per Day
          </Link>
          </ListItem>
          <Divider sx={{ mb: 2 }} />
          <ListItem>
          <Link style={linkStyle} to="/perWeekReport">
            Per Week
          </Link>
          </ListItem>
          <Divider sx={{ mb: 2 }} />
          <ListItem>
          <Link style={linkStyle} to="/raw-data-table">
            Raw Data Table
          </Link>
          </ListItem>
        </>
      )}
    </>
  );
}

export default Weblinks;
