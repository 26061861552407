import React from "react";
import { Container } from "@mui/material";
import NavBar from "../components/Navigation/Navbar";
import SignInFormContainer from "../components/SignInForm/SignInFormContainer";

export default function SignInForm() {
  return (
    <>
      <NavBar></NavBar>
      <Container maxWidth="lg" sx={{ minHeight: "100vh", mt: 8, pt: 2 }}>
        <SignInFormContainer />
      </Container>
    </>
  );
}
