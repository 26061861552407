import { DateTime } from "luxon";
import { useState, useCallback } from "react";
import { Typography } from "@mui/material";
import { GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid-pro";
import { useRecoilValue } from "recoil";
import { selectedYearAtom } from "../recoil/atoms";
import { YEAR } from "../types/types";
import { yearsObj } from "../libraries/objects";

const useBuildPerWeekColumns = () => {
  const selectedYear = useRecoilValue(selectedYearAtom);
  
  const buildColumns = () => {
    let year;
    let week;
    let date;

    const columnArray: GridColDef[] = [
      {
        field: "childFirstName",
        headerName: `First Name`,
        minWidth: 100,
        flex: 0.6,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
      {
        field: "childLastName",
        headerName: `Last Name`,
        minWidth: 100,
        flex: 0.6,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      },
    ];
    for (let i = 0; i < 52; i++) {
      if (i < 26) {
        year = yearsObj[selectedYear].start;
        week = i + 27;
      } else {
        year = yearsObj[selectedYear].end;
        week = i - 25;
      }

      date = DateTime.fromObject({
        weekYear: year,
        weekNumber: week,
      });
      const dateFromStr = date.startOf("week").minus({ day: 1 });
      const dateToStr = date.endOf("week").minus({ day: 1 });

      const columnName = year.toString() + week.toString();

      const columnObj: GridColDef = {
        field: columnName,
        headerName: `${dateFromStr.month}/${dateFromStr.day} - ${dateToStr.month}/${dateToStr.day}`,
        minWidth: 110,
        flex: 0.6,
        headerClassName: "tableHeader",
        cellClassName: "hoverPointer",
      };
      columnArray.push(columnObj);
    }

    return columnArray;
  };
  return { buildColumns };
};

export default useBuildPerWeekColumns;
