import { useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useReactToPrint } from "react-to-print";
import PrintTable from "./PrintTable";
import { TardyLogRecord } from "../../types/types";

type Props = {
  tardyLogs: TardyLogRecord[];
  earlyPickUpLogs: TardyLogRecord[];
};

function PrintContainer({ tardyLogs, earlyPickUpLogs }: Props) {
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <Button onClick={handlePrint} variant="contained">
        Print
      </Button>
      <Box sx={{ display: "none" }}>
        <Box
          ref={componentRef}
          sx={{
            width: "8.5in",
            padding: 0,
            margin: "0px auto",
            backgroundColor: "#ffffff",
            height: "11in",
          }}
        >
          <PrintTable tardyLogs={tardyLogs} earlyPickUpLogs={earlyPickUpLogs} />
        </Box>
      </Box>
    </>
  );
}

export default PrintContainer;
